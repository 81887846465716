import { FC, ReactElement, useState } from 'react';
import clsx from 'clsx';
import styles from './Button.module.scss';

enum HomeButtonState {
    Unlock = 'UNLOCK',
    Race = 'RACE',
    Charge = 'CHARGE',
    Charging = 'CHARGING',
}

const HomeButton: FC = (): ReactElement => {
    const [type, setType] = useState<HomeButtonState>(HomeButtonState.Unlock);

    const handleClick = () => {
        switch (type) {
            case HomeButtonState.Unlock:
                setType(HomeButtonState.Race);
                break;
            case HomeButtonState.Race:
                setType(HomeButtonState.Charge);
                break;
            case HomeButtonState.Charge:
                setType(HomeButtonState.Charging);
                break;
            case HomeButtonState.Charging:
                setType(HomeButtonState.Unlock);
                break;
        }
    };

    switch (type) {
        case HomeButtonState.Unlock:
            return (
                <button className={styles.button} onClick={handleClick}>
                    Unlock: 5,000 MGTN
                </button>
            );
        case HomeButtonState.Charge:
            return (
                <button className={styles.button} onClick={handleClick}>
                    Charge
                </button>
            );
        case HomeButtonState.Charging:
            return (
                <button className={clsx(styles.button, styles.progress)} onClick={handleClick}>
                    Charging 7h:59m
                </button>
            );
        case HomeButtonState.Race:
            return (
                <button className={styles.button} onClick={handleClick}>
                    Go to Race
                </button>
            );
        default:
            return (
                <button className={styles.button} onClick={handleClick}>
                    Go to Race
                </button>
            );
    }
};

export default HomeButton;
