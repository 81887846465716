import { AxiosError } from 'axios';
import ReactGA from 'react-ga4';
import { getRawInitData } from '../helpers/getInitData';
import { torApi, InitDataDto, UpdatedUserInfoDto } from '../api/tor-api';

const API_URL = process.env.REACT_APP_API_URL;
console.log('api: ' + API_URL);
let signInCalled = false;

async function getGaClientId() {
    return new Promise<string>((res) => {
        try {
            console.log('getGaClientId: ga');
            if (process.env.REACT_APP_GA_MEASUREMENT_ID) {
                ReactGA.ga((tracker: any) => {
                    console.log('tracker', tracker);
                    let clientId = tracker.get('clientId');
                    res(clientId);
                });
            } else {
                res('');
            }
        } catch (e) {
            console.error('getGaClientId: error', e);
            res('');
        }
    });
}

export async function doSignIn(): Promise<InitDataDto | null> {
    console.log('doSignIn');
    if (signInCalled) {
        console.log('doSignIn: signInCalled');
        return null;
    }
    const gaClientId = await getGaClientId();
    console.log('doSignIn: gaClientId', gaClientId);
    console.log('doSignIn: start');
    signInCalled = true;
    const launchParams = getRawInitData();
    const response = await torApi.api.postApiV1AuthSignIn({
        initData: launchParams.initDataRaw as string,
        refCode: launchParams.startParam,
        gaTag: gaClientId,
    })
    const initData = response.data
    torApi.setSecurityData(initData.token)
    ReactGA.set({
        telegramId: initData.user.telegramId,
        username: initData.user.username,
        status: initData.user.status,
        isPremium: initData.user.isPremium,
        createdAt: initData.user.createdAt,
        refCode: initData.user.refCode,
    });
    console.log('doSignIn: initData', initData);
    return initData;
}

export async function makeTapRaw(speed: number): Promise<UpdatedUserInfoDto | boolean> {
    try {
        let axiosResponse = await torApi.api.postApiV1FarmTap({ speed })
        return axiosResponse.data;
    } catch (e) {
        console.log(e);
        let e1 = e as AxiosError;
        if (e1?.response?.status === 409 || e1?.response?.status === 404) {
            return false;
        }
        if (e1?.response?.status === 422) {
            return true;
        } else {
            throw e;
        }
    }
}
