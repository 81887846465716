import clsx from 'clsx';
import { FC } from 'react';
import styles from './FarmingProgress.module.scss';
import { Countdown } from '../Countdown';
import { useStore } from '../../../../../store/globalStore';
import ClaimButton from '../ClaimButton/ClaimButton';

interface FarmingProgressProps {
    isLoadingClaim: boolean;
    onClaim(): Promise<void>;
    visible: boolean;
    onCountdownFinished(): void;
    isFarming: boolean;
}

const FarmingProgress: FC<FarmingProgressProps> = ({ isLoadingClaim, visible, onClaim, isFarming, onCountdownFinished }) => {
    const config = useStore((state) => state.config);
    const user = useStore((state) => state.user);

    return (
        <div className={clsx(styles.wrapper, visible ? styles.active : null)}>
            <div className={styles.textContainer}>
                <h3>{isFarming ? 'Farming in progress' : 'Farming completed!'}</h3>
                {isFarming && (
                    <Countdown
                        endTime={user?.farmSessionInfo?.endTime}
                        serverTimeDiffMs={config?.serverTimeDiffMs}
                        className={styles.countdown}
                        onFinished={onCountdownFinished}
                    />
                )}
                <p>
                    {isFarming
                        ? 'Don’t forget to come back to claim the harvest!'
                        : 'Claim your MTGN and start farming again'}
                </p>
            </div>

            <div className={styles.buttonContainer}>
                <ClaimButton
                    isFarming={isFarming}
                    onClick={onClaim}
                    disabled={isFarming || isLoadingClaim}
                />
            </div>
        </div>
    );
};

export default FarmingProgress;
