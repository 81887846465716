import { useCountdown } from '../../../../hooks/useCountdown';
import React, { HTMLAttributes, useEffect } from 'react';

interface CountDownProps extends HTMLAttributes<HTMLSpanElement> {
    endTime?: string;
    serverTimeDiffMs?: number;
    onFinished?(): void;
}

export const Countdown: React.FC<CountDownProps> = ({ endTime, serverTimeDiffMs, onFinished, ...rest }) => {
    const countdown = useCountdown(endTime, serverTimeDiffMs);

    useEffect(() => {
        if (onFinished && countdown?.isEnded) {
            onFinished();
        }
    }, [countdown?.isEnded, onFinished]);

    if (!countdown) {
        return null;
    }

    return (
        <span {...rest}>
            {countdown.isEnded
                ? 'Finished'
                : `${countdown.hours.toString().padStart(2, '0')}:${countdown.minutes.toString().padStart(2, '0')}:${countdown.seconds.toString().padStart(2, '0')}`}
        </span>
    );
}
