import { useEffect } from 'react';
import { ChargingStats } from '../../../modules/ChargingStats';
import { HomeButton } from '../../../modules/HomeButton';
import { Status } from '../../../modules/StatusBar';
import { UpgradeButton } from '../../../modules/Upgrade';
import styles from './Home.module.scss';
import roach from '../../../assets/roachMock.png';
import { useStore } from '../../../store/globalStore';

const Home = () => {
    const setIsShowIntro = useStore((state) => state.setIsShowIntro);
    useEffect(() => {
        setIsShowIntro(false);
    }, [setIsShowIntro]);
    return (
        <main className={styles.container}>
            <Status />

            <div className={styles.roachContainer}>
                <img src={roach} alt="roach" />
            </div>

            <div className={styles.bottom}>
                <div className={styles.rowContainer}>
                    <ChargingStats />
                    <UpgradeButton />
                </div>

                <div className={styles.buttonContainer}>
                    <HomeButton />
                </div>
            </div>
        </main>
    );
};

export default Home;
