import { ButtonHTMLAttributes, DetailedHTMLProps, FC } from 'react';
import clsx from 'clsx';
import styles from './ClaimButton.module.scss';
import { ReactComponent as Coin } from '../../../../../assets/mtgn.svg';
import { useFarming } from '../../../../../store/farmingSlice';

interface ClaimButtonProps
    extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
    isFarming: boolean;
}
const ClaimButton: FC<ClaimButtonProps> = ({ isFarming, ...rest }) => {
    const sessionFarmedCoins = useFarming((state) => state.sessionFarmedCoins);

    return (
        <button
            {...rest}
            disabled={rest.disabled || !sessionFarmedCoins}
            className={clsx(styles.button, (isFarming || !sessionFarmedCoins || rest.disabled) && styles.disabled)}
        >
            <span className={styles.label}>
                {isFarming ? 'Farming' : 'Claim'}
            </span>
            <span className={styles.amount}>
                <Coin />
                {sessionFarmedCoins ? parseFloat(sessionFarmedCoins)?.toFixed(2) : 0}
            </span>
        </button>
    );
};

export default ClaimButton;
