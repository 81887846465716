import { create } from 'zustand';
import { immer } from 'zustand/middleware/immer';
import { produce } from 'immer';
import { Config } from '../helpers/store';
import { UpdatedUserInfoDto, UserInfoDto } from '../api/tor-api';
import { mergeDeepRight } from 'ramda';

enum Screen {
    FIRST = 'FIRST',
    CREATE = 'CREATE',
    ACCOUNT = 'ACCOUNT',
}

interface GlobalStore {
    screen: Screen | null;
    user: UserInfoDto | null;
    config: Config;
    isShowIntro: boolean;
    isShowTasksBadge: boolean;

    updateScreen(screen: Screen): void;
    updateUser(user: UserInfoDto | null): void;
    mergeUser(user: UpdatedUserInfoDto): void;
    setConfig(config: Config): void;
    setIsShowIntro(value: boolean): void;
    updateBalance(value: number): void;
    addBalance(value: number): void;
    disableTaskBadge(): void;
}

export const useStore = create<GlobalStore>()(
    immer((set) => ({
        screen: null,
        user: null,
        config: new Config(),
        isShowIntro: false,
        isShowTasksBadge: true,

        updateScreen: (screen) =>
            set((state) => {
                state.screen = screen;
            }),
        updateUser: (user) =>
            set((state) =>
                produce(state, (draft) => {
                    draft.user = user;
                }),
            ),
        mergeUser: (user) =>
            set((state) =>
                produce(state, (draft) => {
                    if (draft.user) {
                        draft.user = mergeDeepRight(draft.user, user) as UserInfoDto
                    }
                }),
            ),
        setConfig: (config) =>
            set((state) => {
                state.config = config;
            }),
        setIsShowIntro: (value) =>
            set((state) => {
                state.isShowIntro = value;
            }),
        updateBalance: (newBalance) =>
            set((state) =>
                produce(state, (draft) => {
                    if (draft.user) {
                        draft.user.balance = newBalance;
                    }
                }),
            ),
        addBalance: (value) =>
            set((state) =>
                produce(state, (draft) => {
                    if (draft.user) {
                        draft.user.balance = (draft.user.balance ?? 0) + value
                    }
                }),
            ),
        disableTaskBadge: () => set({ isShowTasksBadge: false }),
    })),
);
