import { Vector3 } from 'three';
import { UpdatedUserInfoDto } from '../api/tor-api';

export function getServerTime(serverTimeDiff: number | undefined) {
    return new Date(new Date().getTime() + (serverTimeDiff ?? 0));
}

export function isFarmingSessionFinished(
    user: UpdatedUserInfoDto | null,
    serverTimeDiff: number | undefined,
): boolean {
    return (
        user != null &&
        user.farmSessionInfo != null &&
        user.farmSessionInfo.endTime != null &&
        new Date(user.farmSessionInfo.endTime) <= getServerTime(serverTimeDiff)
    );
}

export function isFarmingSessionActive(
    user: UpdatedUserInfoDto | null,
    serverTimeDiff: number | undefined,
): boolean {
    return (
        user != null &&
        user.farmSessionInfo != null &&
        user.farmSessionInfo.endTime != null &&
        new Date(user.farmSessionInfo.endTime) > getServerTime(serverTimeDiff)
    );
}

export function canStartFarmingSession(user: UpdatedUserInfoDto | null): boolean {
    return user != null && user.farmSessionInfo == null;
}

export function clamp(value: number, min: number, max: number): number {
    return Math.max(min, Math.min(max, value));
}

export function lerp(a: number, b: number, t: number): number {
    return a * (1 - t) + b * t;
}

export function lerpVector3(a: Vector3, b: Vector3, t: number): Vector3 {
    return a
        .clone()
        .multiplyScalar(1 - t)
        .add(b.clone().multiplyScalar(t));
}

export function randomRange(min: number, max: number) {
    return Math.random() * (max - min) + min;
}
