import { FC } from 'react';
import clsx from 'clsx';
import styles from './Status.module.scss';
import { ReactComponent as Coin } from '../../../assets/mtgn.svg';

const Status: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.segment}>
                <span className={styles.icon}>🎖️</span>
                24,691<span className={styles.endChars}>th</span>
            </div>
            <div className={clsx(styles.segment, styles.balance)}>
                <Coin className={styles.coin} />
                <span className="">56,783</span>
            </div>
            <div className={styles.segment}>
                <span className={styles.icon}>⚡️</span>
                100 <span className={styles.endChars}>/hour</span>
            </div>
        </div>
    );
};

export default Status;
