import React, { useEffect, useRef, useState } from 'react';
import { Button } from 'antd';
import clsx from 'clsx';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { useHandleError } from '../../../../hooks/useHandleError';
import { useSDKWrapper } from '../../../../helpers/sdkWrapper';
import { useStore } from '../../../../store/globalStore';
import { TaskItemStatus, TaskItemDto, torApi } from '../../../../api/tor-api';

export const Tasks: React.FC = () => {
    const [isLoadedFirst, setIsLoadedFirst] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [tasks, setTasks] = useState<TaskItemDto[] | null>(null);
    const [taskLoading, setTaskLoading] = useState<{ [k: string]: boolean }>({});

    const audioClaim = useRef(new Audio('/music/claim.mp3'));

    const { handleError } = useHandleError();
    const { openLink } = useSDKWrapper();
    const updateBalance = useStore((state) => state.updateBalance);
    const disableTaskBadge = useStore((state) => state.disableTaskBadge);

    const fetchTasks = async () => {
        if (!isLoading) {
            setIsLoading(true);
            try {
                const result = await torApi.api.getApiV1Tasks()
                setTasks(result.data.tasks ?? []);
            } catch (e) {
                handleError(e as AxiosError);
            }

            if (!isLoadedFirst) {
                setIsLoadedFirst(true);
            }

            setIsLoading(false);
        }

        setTimeout(() => {
            disableTaskBadge();
        }, 1500);
    };

    const onClaimTask = (task: TaskItemDto) => async () => {
        audioClaim?.current?.play?.();

        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: true,
        }));

        try {
            const result = await torApi.api.postApiV1TasksClaim({ taskId: task.id });

            if (result.data.user.balance) {
                updateBalance(result.data.user.balance);
            }

            setTasks(result.data.tasks);
        } catch (e) {
            handleError(e as AxiosError);
            toast('Something went wrong', {
                hideProgressBar: true,
                type: 'error',
                icon: false,
                autoClose: 3000,
                theme: 'dark',
                position: 'top-center',
            });
        }
        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: false,
        }));
    };

    const onStartTask = (task: TaskItemDto) => async () => {
        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: true,
        }));
        try {
            const result = await torApi.api.postApiV1TasksStart({ taskId: task.id })
            setTasks(result.data.tasks);
            if (task.subscribeTaskInfo?.url) {
                openLink(task.subscribeTaskInfo?.url);
            }
        } catch (e) {
            handleError(e as AxiosError);
            toast('Something went wrong', {
                hideProgressBar: true,
                type: 'error',
                icon: false,
                autoClose: 3000,
                theme: 'dark',
                position: 'top-center',
            });
        }
        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: false,
        }));
    };

    const onCheckTask = (task: TaskItemDto) => async () => {
        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: true,
        }));
        try {
            const result = await torApi.api.postApiV1TasksCheck({ taskId: task.id })
            setTasks(result.data.tasks);
        } catch (e) {
            handleError(e as AxiosError);
            toast('Something went wrong', {
                hideProgressBar: true,
                type: 'error',
                icon: false,
                autoClose: 3000,
                theme: 'dark',
                position: 'top-center',
            });
        }
        setTaskLoading((prev) => ({
            ...prev,
            [task.id]: false,
        }));
    };

    useEffect(() => {
        fetchTasks();

        // eslint-disable-next-line
    }, []);

    console.log(tasks);

    return (
        <div className="flex flex-col flex-grow w-full  py-5">
            <div className="flex flex-col gap-3 flex-grow">
                <span className="font-druk text-2xl leading-8 font-bold px-4">Available Tasks</span>
                <span className="font-mono text-[13px] text-[#B3B3B3] px-4">
                    We'll reward you immediately with points after each task completion.
                </span>
                <div className="flex flex-col flex-grow max-h-full">
                    <div className="flex flex-col overflow-auto max-h-[calc(100vh-240px)]">
                        {tasks?.map((task) => (
                            <div
                                className="px-4 py-3 flex gap-4 justify-between border-b border-[#2B2B2B]"
                                key={task.id}
                            >
                                <div className="flex gap-4 align-middle">
                                    <div
                                        className={clsx(
                                            'flex flex-shrink-0 w-9 h-9 items-center justify-center rounded-full relative',
                                            {
                                                'border border-[#2B2B2B] text-[#E6E6E6]':
                                                    task.status === TaskItemStatus.OPENED ||
                                                    task.status === TaskItemStatus.AUTO,
                                                'border border-[#3EDC03] bg-[#3EDC03] text-[black]':
                                                    task.status === TaskItemStatus.CAN_CLAIM,
                                                'border border-[#2B2B2B] bg-[#1A1A1A] text-[white]':
                                                    task.status === TaskItemStatus.CLAIMED,
                                            },
                                        )}
                                    >
                                        {task.id === 'telegram_rrc' ||
                                        task.id === 'telegram_rrc_chat' ||
                                        task.id === 'interact_bot' ? (
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.6667 1.18656L12.3467 14.7231C12.3467 14.7231 12.0221 15.6616 11.1304 15.2115L5.77757 10.4614L5.75275 10.4474C6.47579 9.69596 12.0826 3.86145 12.3276 3.59698C12.7069 3.18738 12.4714 2.94354 12.031 3.25295L3.7491 9.34014L0.553966 8.09589C0.553966 8.09589 0.051146 7.88888 0.00277342 7.43877C-0.0462356 6.98792 0.570515 6.74408 0.570515 6.74408L13.5961 0.830006C13.5961 0.830006 14.6667 0.285598 14.6667 1.18656Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        ) : task.id === 'x_rrc' ||
                                          task.id === 'x_roach_coach_rrc' ? (
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    clipRule="evenodd"
                                                    d="M5.99386 8.38403L0 2H4.74756L8.44753 5.94577L12.4004 2.01777H15.0151L9.71175 7.29399L16 14H11.2666L7.2603 9.7329L2.98317 13.9882H0.354296L5.99386 8.38403ZM11.9565 12.8172L2.91105 3.18285H4.0571L13.0912 12.8172H11.9565Z"
                                                    fill="white"
                                                />
                                            </svg>
                                        ) : (
                                            <svg
                                                width="18"
                                                height="18"
                                                viewBox="0 0 18 18"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M14.1947 4.5827L10.1867 2.23827C9.45433 1.80941 8.55043 1.80941 7.8165 2.23827L3.80691 4.5827C3.07457 5.01156 2.625 5.80098 2.625 6.65553V11.3444C2.625 12.1989 3.07616 12.9899 3.80691 13.4172L7.81491 15.7616C8.54884 16.1905 9.45115 16.1905 10.1851 15.7616L14.1931 13.4172C14.9254 12.9899 15.375 12.1989 15.375 11.3444V6.65711C15.3766 5.80098 14.927 5.01156 14.1947 4.5827ZM3.55432 6.65553C3.55432 6.12819 3.83074 5.64215 4.28031 5.37848L8.27719 3.03881C8.72517 2.77673 9.27641 2.77673 9.72439 3.03881L12.0072 4.37622L5.08572 8.42497L3.55432 7.52913V6.65553ZM8.90786 7.25116L12.0008 9.06032L9.08737 10.7646L5.99439 8.95548L8.90786 7.25116ZM4.28031 12.6119C3.83074 12.3498 3.55432 11.8622 3.55432 11.3349V8.59175L8.1787 11.2967L5.10478 13.0948L4.28031 12.6119ZM14.4473 11.3349C14.4473 11.8622 14.1708 12.3482 13.7213 12.6119L9.72439 14.9516C9.27641 15.2137 8.72517 15.2137 8.27719 14.9516L6.01187 13.6253L12.9095 9.59083L14.4473 10.4898V11.3349ZM14.4473 9.42882L9.81653 6.72065L12.9159 4.90832L13.7213 5.38007C14.1708 5.64215 14.4473 6.12978 14.4473 6.65711V9.42882Z"
                                                    fill="currentColor"
                                                />
                                            </svg>
                                        )}

                                        {task.status === TaskItemStatus.CLAIMED && (
                                            <div className="absolute -right-0.5 -bottom-0.5">
                                                <svg
                                                    width="12"
                                                    height="12"
                                                    viewBox="0 0 12 12"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                >
                                                    <g clipPath="url(#clip0_2457_11558)">
                                                        <circle
                                                            cx="6"
                                                            cy="6"
                                                            r="6"
                                                            fill="#1A1A1A"
                                                        />
                                                        <path
                                                            d="M6 0C9.31371 0 12 2.68629 12 6C12 9.31371 9.31371 12 6 12C2.68629 12 0 9.31371 0 6C0 2.68629 2.68629 0 6 0ZM9.22372 4.09091H8.37509C8.34616 4.09091 8.31842 4.1024 8.29796 4.12286L5.28879 7.13161L3.91613 5.75901C3.89568 5.73855 3.86793 5.72706 3.839 5.72706H2.99056C2.96164 5.72706 2.9339 5.73855 2.91344 5.759C2.87083 5.80159 2.87082 5.87066 2.91341 5.91328C2.96137 5.96125 3.00933 6.00923 3.05728 6.05721C3.0703 6.07023 3.08359 6.08354 3.09716 6.09713L3.18167 6.18183C3.35659 6.3573 3.56533 6.56749 3.77782 6.78182L3.93743 6.94286C4.43326 7.44334 4.90865 7.92476 4.9818 7.99869L4.99118 8.00815C5.15094 8.16792 5.40997 8.16792 5.56973 8.00815L9.30085 4.27714C9.32131 4.25668 9.33281 4.22893 9.33281 4.2C9.33281 4.13975 9.28397 4.09091 9.22372 4.09091Z"
                                                            fill="white"
                                                        />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_2457_11558">
                                                            <rect
                                                                width="12"
                                                                height="12"
                                                                fill="white"
                                                            />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            </div>
                                        )}
                                    </div>
                                    <div className="flex flex-col">
                                        <span className="font-mono text-[15px] font-bold text-[#E6E6E6]">
                                            {task.reward}&#8201;MTGN
                                        </span>
                                        <span className="font-mono text-[11px] text-[#B3B3B3]">
                                            {task.description}
                                        </span>
                                    </div>
                                </div>
                                {task.status === TaskItemStatus.OPENED && (
                                    <Button
                                        className="font-semibold font-mono !text-[15px]"
                                        onClick={onCheckTask(task)}
                                        disabled={taskLoading[task.id]}
                                    >
                                        Check
                                    </Button>
                                )}

                                {task.status === TaskItemStatus.CAN_CLAIM && (
                                    <Button
                                        type="primary"
                                        className="!text-black !text-[15px] font-semibold font-mono"
                                        onClick={onClaimTask(task)}
                                        disabled={taskLoading[task.id]}
                                    >
                                        Claim
                                    </Button>
                                )}
                                {task.status === TaskItemStatus.CAN_OPEN && (
                                    <Button
                                        className="font-semibold font-mono !text-[15px]"
                                        onClick={onStartTask(task)}
                                        disabled={taskLoading[task.id]}
                                    >
                                        Start
                                    </Button>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
};
