import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { Main } from './pages/main';
import { mainTheme } from './helpers/colors';
import { isDebugMode } from './helpers/config';
import { Plug } from './pages/main/DevicePlug';

function App() {
    console.log('App: render');
    return (
        <ConfigProvider theme={mainTheme}>
            <div className="flex flex-col h-full w-full items-center justify-center bg-black">
                {!isDebugMode() && <Plug />}
                <Router>
                    <Routes>
                        <Route path="/" element={<Main />} />
                    </Routes>
                </Router>
            </div>
        </ConfigProvider>
    );
}

export default App;
