import { FC } from 'react';

import styles from './IntroOverlay.module.scss';

const IntroOverlay: FC = () => {
    return (
        <div className={styles.overlayContainer}>
            <div className={styles.textContainer}>
                <h3>Tap {100} times to start farming process</h3>
                <p>Every tap will also give you a bit of extra MTGN!</p>
            </div>
            <div className={styles.tapContainer}>
                <span className={styles.tap}>TAP</span>
            </div>
        </div>
    );
};

export { IntroOverlay };
