import { FC } from 'react';
import styles from './ChargingStats.module.scss';

const ChargingStats: FC = () => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.segment}>
                <span className={styles.icon}>🔋</span>
                400
            </div>
            <div className={styles.segment}>
                <span className={styles.icon}>⚡️</span>
                10 <span>/hour</span>
            </div>
        </div>
    );
};

export default ChargingStats;
